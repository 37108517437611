<template>
  <div class="x-wrap">
    <div class="x-page-title">
      <h3>提现审核</h3>
    </div>

    <div class="x-main">
      <!-- 筛选条件 -->
      <div class="x-q-panel">
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="x-con-item">
              <label>提现用户：</label>
              <el-input
                placeholder="请输入提现用户"
                v-model="query.userLike"
              ></el-input>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="x-con-item">
              <label>收款账户：</label>
              <el-input
                placeholder="请输入收款账户"
                v-model="query.bankCardLike"
              ></el-input>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="x-con-item">
              <label>提现时间：</label>
              <el-date-picker
                v-model="query.timeArr"
                type="daterange"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="x-con-item mt25">
              <label>提现编号：</label>
              <el-input
                placeholder="请输入提现编号"
                v-model="query.idLike"
              ></el-input>
            </div>
          </el-col>

          <el-col :span="6">
            <div class="x-con-item mt25">
              <label>审核状态：</label>
              <el-select placeholder="请选择审核状态" v-model="query.status">
                <el-option label="待审核" value="1"></el-option>
                <el-option label="已驳回" value="2"></el-option>
                <el-option label="已取消" value="3"></el-option>
                <el-option label="待付款 " value="4"></el-option>
                <el-option label="付款中" value="5"></el-option>
                <el-option label="已付款" value="6"></el-option>
                <el-option label="付款失败" value="7"></el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="3">
            <el-button
              type="primary"
              class="x-q-btn mt25"
              @click="
                query.pageIndex = 1;
                doQuery();
              "
              >筛选</el-button
            >
            <el-button type="text" @click="doQueryReset">重置</el-button>
          </el-col>
        </el-row>
      </div>

      <!-- 筛选结果 -->
      <div class="x-table-container">
        <div class="x-table-caption">
          <el-button
            icon="el-icon-refresh"
            type="primary"
            plain
            size="small"
            @click="doRefresh"
            >刷新</el-button
          >
        </div>

        <div
          class="x-table"
          v-loading="showLoading"
          element-loading-text="数据刷新中..."
        >
          <el-table :data="tableData" stripe style="width: 100%">
            <el-table-column prop="id" label="编号" width="120">
            </el-table-column>
            <el-table-column prop="amount" label="提现金额" align="center">
            </el-table-column>
            <el-table-column prop="nickName" label="提现用户"></el-table-column>
            <el-table-column
              prop="realName"
              label="用户真实姓名"
            ></el-table-column>
            <el-table-column
              prop="phoneNumber"
              label="用户手机号码"
            ></el-table-column>
            <el-table-column
              prop="bankCardNo"
              label="提现银行卡号"
            ></el-table-column>
            <!-- <el-table-column prop="" label="提现时间" align="center"> -->
            <!-- </el-table-column> -->

            <el-table-column prop="sellCount" label="提现状态" align="status">
              <template slot-scope="scope">
                <span class="font-normal" v-if="scope.row.status == 1"
                  >待审核</span
                >
                <span class="font-normal" v-else-if="scope.row.status == 2"
                  >已驳回</span
                >
                <span class="font-normal" v-else-if="scope.row.status == 4"
                  >待付款</span
                >
                <span class="font-normal" v-else-if="scope.row.status == 5"
                  >付款中</span
                >
                <span class="font-normal" v-else-if="scope.row.status == 6"
                  >已付款</span
                >
                <span class="font-normal" v-else-if="scope.row.status == 7"
                  >付款失败</span
                >
              </template>
            </el-table-column>

            <el-table-column label="操作" align="center" width="180">
              <template slot-scope="scope">
                <div class="flex-box-around">
                  <el-link
                    :underline="false"
                    type="success"
                    @click="goPass(scope.row.id)"
                    :disabled="scope.row.status != 1"
                    >通过</el-link
                  >
                  <el-link
                    :underline="false"
                    type="danger"
                    :disabled="scope.row.status != 1"
                    @click="goFail(scope.row.id)"
                    >驳回</el-link
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>

          <div class="x-pager right">
            <el-pagination
              background
              layout="sizes, prev, pager, next"
              :current-page.sync="query.pageIndex"
              :page-size="query.pageSize"
              :page-sizes="[10, 20, 30, 50]"
              :total="total"
              @size-change="setPageSize"
              @current-change="setPageIndex"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BalanceQuery, CashoutReview } from "@/api/module/balance";

export default {
  name: "CashoutIndex",
  data() {
    return {
      showLoading: false,
      query: {
        status: "",
        userLike: "",
        bankCardLike: "",
        timeArr: [],
        idLike: "",
        pageIndex: 1,
        pageSize: 10,
      },
      total: 0,
      tableData: [],
    };
  },

  created() {
    this.doQuery();
  },
  methods: {
    goFail(id) {
      const msg = "<span class='font-danger'>确认要驳回本条数据吗？</span>";
      this.$alert(msg, "驳回提示", {
        dangerouslyUseHTMLString: true,
        showCancelButton: true,
        confirmButtonText: "确定驳回",
        center: true,
        callback: (action) => {
          if (action === "confirm") {
            let oData = {
              id: [id],
              status: "2",
            };
            CashoutReview(oData).then((r) => {
              if (r.code === 0) {
                this.doQuery();
                this.$message({
                  type: "success",
                  message: "操作驳回成功",
                  duration: 1300,
                });
              }
            });
          }
        },
      });
    },
    async goPass(id) {
      let oData = {
        id: [id],
        status: "4",
      };
      const r = await CashoutReview(oData);
      if (r.code == 0) {
        this.$message({
          type: "success",
          message: "操作通过成功",
          duration: 1300,
        });
      }
    },
    // 查询
    doQuery() {
      let query = this.setQueryParams();
      BalanceQuery(query).then((r) => {
        this.total = r.total;
        this.tableData = r.list;
      });
    },

    // 设置筛选
    setQueryParams() {
      let query = `?pagination=true&page=${this.query.pageIndex}&pageSize=${this.query.pageSize}`;
      if (this.query.userLike) {
        query += `&userLike=${this.query.userLike}`;
      }
      if (this.query.bankCardLike) {
        query += `&bankCardLike=${this.query.bankCardLike}`;
      }
      if (this.query.userLike) {
        query += `&userLike=${this.query.userLike}`;
      }
      if (this.query.idLike) {
        query += `&idLike=${this.query.idLike}`;
      }
      if (this.query.status) {
        query += `&status=${this.query.status}`;
      }
      if (this.query.timeArr.length) {
        query += `&startTime=${this.query.timeArr[0]}&endTime=${this.query.timeArr[1]}`;
      }
      return query;
    },

    // 重置筛选
    doQueryReset() {
      this.query = {
        status: "",
        userLike: "",
        bankCardLike: "",
        timeArr: [],
        idLike: "",
        pageIndex: 1,
        pageSize: 10,
      };
      this.doQuery();
    },

    // 刷新
    doRefresh() {
      this.showLoading = true;
      this.doQueryReset();
      setTimeout(() => {
        this.$message({
          type: "success",
          message: "数据更新完毕！",
          duration: 1300,
          onClose: () => {
            this.showLoading = false;
          },
        });
      }, 1350);
    },

    // 设置分页
    setPageSize(pageSize) {
      this.query.pageIndex = 1;
      this.query.pageSize = pageSize;
      this.doQuery();
    },

    // 设置页码
    setPageIndex(pageIndex) {
      this.query.pageIndex = pageIndex;
      this.doQuery();
    },
  },
};
</script>



